<template>
  <ProfileComponent></ProfileComponent>
</template>

<script>
import ProfileComponent from '@/components/Profile.vue'
export default {
  components: { ProfileComponent},
  name: 'MyProfile'
}
</script>
